
<template>
  <div class="box">
    <div class="top">
      <div class="org">
        <span>{{OrgName}}</span><span>{{miniGridName?gridName+'/'+miniGridName:gridName}}</span>
      </div>
      <van-divider />
      <div class="content">
        <div class="orgname"><span class="span1">{{buildingName}}</span><span class="span2">{{divideUnit}}单元{{divideHouse}}房</span></div>
        <div class="user" v-if="gridManger.length>0">
          <span >网格长:&nbsp;<span class="phone">{{gridManger[0].name}}&nbsp;{{gridManger[0].mobile}}</span></span> <img src="@/assets/img/mobile.png" alt="" class="tel" @click.stop="getRealMobile(gridManger[0].id)"/>
        </div>
        <div v-else class="user"><span>暂无网格长信息</span></div>
        <div class="user" v-if="buildingAssistant.length>0">
          <span>协管员:&nbsp;<span class="phone">{{buildingAssistant[0].name}}&nbsp;{{buildingAssistant[0].mobile}}</span></span> <img src="@/assets/img/mobile.png" alt="" class="tel" @click.stop="getRealMobile(buildingAssistant[0].id)"/>
        </div>
        <div v-else class="user"><span >暂无协管员信息</span></div>
      </div>
      <van-divider />
      <div class="button-box">
        <button class="btn" @click="goRepair(userId)" v-if="userId">报修</button>
        <button class="btn"   @click="goProblem(userId)" v-if="userId">问题</button>
        <button class="btn"  @click="checkInfos(userId)" v-if="userId">巡查</button>
        <button class="btn"  @click="goVisit(userId)" v-if="userId">走访</button>
      </div>
    </div>

    <div class="box-list">
      <div class="title"><span>房屋信息</span><span class="title-end">房屋总数：<span class="end-num">{{totalHouse}}</span><span class="end-DW">（套）</span></span></div>
      <div class="numList">
        <div class="ega" v-for="item in HouseTypeList"  @click="goHouse(item.value)">
          <span class="number" :style="{color:(item.value==8||item.value==7)?'red':'black'}">{{item.number}}</span>
          <span class="type" :style="{color:(item.value==8||item.value==7)?'red':'black'}">{{item.label}}</span>
        </div>

      </div>

    </div>
    <div class="box-list">
      <div class="title"><span>人员信息</span><span class="title-end">人员总数：<span class="end-num">{{userTotal}}</span><span class="end-DW">（人）</span></span></div>
      <div class="numList">
        <div class="ega" v-for="item in userTypeList"  @click="goRes(item.value)">
          <span class="number" :style="{color:(item.value==20||item.value==21)?'red':'black'}">{{item.count}}</span>
          <span class="type" :style="{color:(item.value==20||item.value==21)?'red':'black'}" >{{item.label}}</span>
        </div>
      </div>

    </div>
    <div class="box-list" v-if="label.length>0">
      <div class="title"><span>标签信息</span></div>
      <div class="numList">
        <div class="ega" v-for="item in label" @click="goResLabel(item.value)">
          <span class="number">{{item.num}}</span>
          <span class="type">{{item.label}}</span>
        </div>
      </div>

    </div>
    <div class="box-list" v-if="shop.length>0">
      <div class="title"><span>店铺信息</span></div>
      <div class="numList">
        <div class="ega" v-for="item in shop" @click="goShop(item.id)">
          <span class="number">{{item.count}}</span>
          <span class="type">{{item.type}}</span>
        </div>
      </div>

    </div>
    <div class="box-list" v-if="event.length>0">
      <div class="title"><span>近一个月事件信息</span></div>
      <div class="numList">
        <div class="ega" v-for="item in event" @click="goApp(item.text)">
          <span class="number">{{item.count}}</span>
          <span class="type">{{item.text}}</span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {getRealMobile} from "@/utils/common";
import Vue from "vue";

export default {
  data(){
    return{
      AppUrl:'https://h5.app.hzuht.com/app',
      userTotal:0,
      totalHouse:0,
      OrgName:'',
      gridName:'',
      miniGridName:'',
      buildingName:'',
      event:[],//近一个月事件
      house:[],//房屋信息
      label:[],//标签信息
      person:[],//人员信息
      shop:[],//店铺信息
      buildingAssistant:[],//协管员
      gridManger:[],//网格长
      openid:'',
      unionid:'',
      buildingId:'',
      userTypeList:[],
      HouseTypeList:[],
      userId:'',
      orgId:'',
      divideUnit:'',//单元
      divideHouse:'',
      assistId:'' ,//协管员id
      // isVisitOpen:false,//是否开启访客功能
      repairAppInfo: {},//报修
      problemAppInfo:{},//问题
      checkInfo:{},//巡查
      visitAppInfo: {},//走访
      userName:'',
    }
  },
  created() {
    this.userName = this.$globalData.userInfo.userName
    console.log(this.$route.query)
  this.orgId=this.$orgId
  this.userId= Vue.prototype.$globalData.userInfo.userId
    console.log(this.$route.query.buildingId)
    this.assistId=this.$route.query.assistId
    console.log(this.assistId)
    this.buildingId=this.$globalData.buildingId

    // this.getUserdict()
    this.getHousedict()
    this.getAppInfo()

  },
  mounted() {
    this.getHouse()
  },
  methods:{
    getAppInfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/application/geMyAppInfoByCode'),
        method: 'GET',
        params: this.$http.adornParams({
          codes: 'sjgl_bxjl,sjgl_wtjl,sjgl_sgzf_zfjl,sjgl_xcjl',
          orgId: this.$orgId
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          data.list.forEach(item => {
            if(item.appCode === 'sjgl_sgzf_zfjl') {
              this.visitAppInfo = item
            }
            if(item.appCode === 'sjgl_bxjl') {
              // this.isRepairsOpen = true
              this.repairAppInfo = item
            }
            if(item.appCode === 'sjgl_wtjl') {
              // this.isProblemOpen = true
              this.problemAppInfo = item
            }
            if(item.appCode === 'sjgl_xcjl') {
              // this.isVisitOpen = true
              this.checkInfo = item
            }
          })
        }
      })
    },
    goVisit(item) {
      let url = this.visitAppInfo.pageUrlH5.split('=')[0] + '=visit'
      this.$router.push({
        path: '/appSSO',
        query: {
          SM:'saoma',
          userId:item,
          appId: this.visitAppInfo.appId,
          appCode: this.visitAppInfo.appCode,
          appName: this.visitAppInfo.appName,
          url: url,
          // url: 'http://192.168.0.12:8081?url=visit',
          subarea: this.subarea + '',
          buildingId: this.buildingId + ''
        }
      })
    },
    //跳转报修
    goRepair(item) {
      let url = this.repairAppInfo.pageUrlH5.split('=')[0] + '=repair-add'
      this.$router.push({
        path: '/appSSO',
        query: {
          SM:'saoma',
          userId:item.id,
          orgId:this.$orgId,
          houseId: item.id+ '',
          appId: this.repairAppInfo.appId,
          appCode: this.repairAppInfo.appCode,
          appName: this.repairAppInfo.appName,
          url: url,
        }
      })
    },
    //跳转问题
    goProblem(item) {
      let url = this.problemAppInfo.pageUrlH5.split('=')[0] + '=problem-add'
      this.$router.push({
        path: '/appSSO',
        query: {
          buildingId:this.buildingId,
          SM:'saoma',
          orgId:this.$orgId,
          houseId: item.id + '',
          appId: this.problemAppInfo.appId,
          appCode: this.problemAppInfo.appCode,
          appName: this.problemAppInfo.appName,
          url: url
          // url: this.problemAppInfo.pageUrlH5,
        }
      })
    },
    //跳转巡查
    checkInfos(item){
      let url = this.checkInfo.pageUrlH5.split('=')[0] + '=patrol-add'
      this.$router.push({
        path: '/appSSO',
        query: {
          placeId: item.id||'',
          placeType: 2,
          SM:'saoma',
          userId:item.id||'',
          orgId:this.$orgId,
          houseId:item.id + '',
          appId: this.checkInfo.appId,
          appCode: this.checkInfo.appCode,
          appName: this.checkInfo.appName,
          url: url,
        }
      })
    },
    //获取人口性质
    getUserdict(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/virtual'),
        method: 'GET',
        params: this.$http.adornParams({
          code: 'registryType'
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.userTypeList = data.virtualDicts
          this.userTypeList.forEach((item)=>{
            item.number=0
          })
          this.userTypeList.push({value:20,label:'无证件人员',number:0})
          this.userTypeList.push({value:21,label:'身份证有误',number:0})
        }
      })
    },
    //获取房屋性质
    getHousedict(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/virtual'),
        method: 'GET',
        params: this.$http.adornParams({
          code: 'useType'
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.HouseTypeList = data.virtualDicts
          this.HouseTypeList.forEach((item)=>{
            item.number=0
          })
          this.HouseTypeList.push({value:7,label:'群租超限',number:0},{value:8,label:'未签约',number:0})
        }
      })
    },
    //获取信息
    getHouse(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/statistics/count'),
        method: 'GET',
        params: this.$http.adornParams({
          buildingId: this.buildingId
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.totalHouse=data.statistics.totalHouse
          this.userTotal=data.statistics.userTotal
          this.divideUnit=data.statistics.buildingInfo.divideUnit==0?'不分':'分'
          this.divideHouse=data.statistics.buildingInfo.divideHouse==0?'不分':'分'
          this.buildingName=data.statistics.buildingInfo.subareaName +data.statistics.buildingInfo.name+'幢'
          this.subarea = data.statistics.buildingInfo.subarea
          this.OrgName=data.statistics.buildingInfo.communityName
          this.miniGridName=data.statistics.buildingInfo.miniGridName
          this.gridName=data.statistics.buildingInfo.gridName
          if(data.statistics.buildingAssistant && data.statistics.buildingAssistant.length>0) {
            this.buildingAssistant.push(data.statistics.buildingAssistant[0])
          }
          if(data.statistics.gridManger && data.statistics.gridManger.length>0){
            this.gridManger.push(data.statistics.gridManger[0])
          }
          this.event=data.statistics.event
          this.shop=data.statistics.shop
          this.label=data.statistics.label
          data.statistics.house.forEach((val)=>{
            this.HouseTypeList.find((item)=>{
              if(item.value==val.type){
                this.$set(item, 'number', val.count);

              }
            })
          })
          this.userTypeList=data.statistics.person
          // data.statistics.person.forEach((val)=>{
          //   this.userTypeList.find((item)=>{
          //     if(item.value==val.type){
          //       this.$set(item, 'number', val.count);
          //
          //     }
          //   })
          // })
          this.$forceUpdate();
        }
      })
    },
    getRealMobile(id) {
      getRealMobile(id,function(data) {
        window.location.href = `tel:${data.mobile}`
      })
    },
    //跳转居民详情
    goRes(type){
      if(type!=20&&type!=21){
        this.$router.push({path:'/userRes-index',query:{buildingId:this.buildingId,type:type,Code:'admin',orgId:this.orgId,userId:this.userId,assistId:this.assistId}})
      }

    },
    goResLabel(type){

      this.$router.push({path:'/userRes-index',query:{buildingId:this.buildingId,type:-1,Code:'admin',orgId:this.orgId,userId:this.userId,assistId:this.assistId,label:type}})

    },
    goShop(type){

      this.$router.push({path:'/streetShop',query:{buildingId:this.buildingId,type:type,Code:'admin',orgId:this.orgId,userId:this.userId,assistId:this.assistId}})

    },
    goApp(text){
      if(text=='未处理问题'){
        this.$router.push({
          path: '/appSSO',
          query: {
            solveStatus:10,
            buildingId:this.buildingId,
            SM:'saoma',
            orgId:this.$orgId,
            appId: this.problemAppInfo.appId,
            appCode: this.problemAppInfo.appCode,
            appName: this.problemAppInfo.appName,
            url: this.problemAppInfo.pageUrlH5
            // url: 'http://192.168.0.76:8083/problem'
          }
        })
      }
      if(text=='未处理报修'){
        // let url = this.visitAppInfo.pageUrlH5.split('=')[0] + '=repair-add'
        this.$router.push({
          path: '/appSSO',
          query: {
            solveStatus:10,
            SM:'saoma',
            userId:this.userId,
            orgId:this.$orgId,
            buildingId:this.buildingId,
            appId: this.repairAppInfo.appId,
            appCode: this.repairAppInfo.appCode,
            appName: this.repairAppInfo.appName,
            url: this.repairAppInfo.pageUrlH5
            // url: 'http://192.168.0.76:8083/repair'
          }
        })
      }
      if(text=='走访'){
        // let url = this.visitAppInfo.pageUrlH5.split('=')[0] + '=visit-add'
        this.$router.push({
          path: '/appSSO',
          query: {
            solveStatus:10,
            buildingId:this.buildingId,
            appId: this.visitAppInfo.appId,
            appCode: this.visitAppInfo.appCode,
            appName: this.visitAppInfo.appName,
            url: this.visitAppInfo.pageUrlH5,
            // url: 'http://192.168.0.76:8083/visit'
          }
        })
      }
      if(text=='巡查'){
        this.$router.push({
          path: '/appSSO',
          query: {
            solveStatus:10,
            placeType: 2,
            SM: 'saoma',
            userId: this.userId || '',
            orgId: this.$orgId,
            buildingId:this.buildingId,
            appId: this.checkInfo.appId,
            appCode: this.checkInfo.appCode,
            appName: this.checkInfo.appName,
            url: this.checkInfo.pageUrlH5
            // url: 'http://192.168.0.76:8083/patrol'
          }
        })
      }
    },
    //跳转房屋
    goHouse(type){
      if(type!=8&&type!=7) {
        this.$router.push({
          path: '/house',
          query: {
            buildingId: this.buildingId,
            type: type,
            Code: 'admin',
            orgId: this.orgId,
            userId: this.userId,
            assistId: this.assistId
          }
        })
      }

    }
  }
}
</script>

<style scoped lang="scss">
.box{
  font-family: 'PingFangSC-Regular', serif;
  min-height: 100vh;
  //background-image: url("~@/assets/img/home-bg.png");
  //background-size: 100% calc(100% + 176px);
  //background-position: 0 -176px;
  //background-repeat: no-repeat;
  .top{
    padding: 24px 30px 24px 30px;
    width: 750px;
    height: 450px;
    background: #FFFFFF;
    .button-box{
      margin-top: 22px;
      display: flex;
      justify-content: center;
      gap: 42px;
      .btn {
        //flex: 1;
        width: 200px;
        height: 60px;
        line-height: 60px;
        border: 0;
        font-size: 28px;
        color: #fff;
        font-family: 'PingFangSC-Regular', serif;
        border-radius: 8px;
        background-color: #4581F8;
        text-align: center;
        margin-right: 26px;
        &:last-child {
          margin: 0;
        }
      }
    }
    .van-divider{
      margin: 0;
    }
    .org{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 72px;
      span{
        font-weight: 500;
        font-size: 30px;
        color: #666666;
        line-height: 42px;
        text-align: center;
        font-style: normal;
      }
    }
    .content{
      height:228px;
      .orgname{
        margin-top: 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 52px;
        .span1{
          font-weight: 600;
          font-size: 30px;
          color: #333333;
          line-height: 42px;
          text-align: left;
          font-style: normal;
        }
        .span2{
          color:#4E7CF5;
          font-size: 30px;
          padding:8px 20px 8px 20px;
          height: 52px;
          background: rgba(78,124,245,0.12);
          border-radius: 8px;
        }
      }
      .user{
        display: flex;
        justify-content: space-between;
        margin-top:24px;
        span{
          font-family: 'PingFangSC-Regular', serif;
          font-weight: 400;
          font-size: 28px;
          color: #666666;
          line-height: 40px;
          text-align: center;
          font-style: normal;
          .phone{
            font-family: 'PingFang Bold', serif;
            font-weight: 500;
            color: #333333;
          }
        }
        img{
          width: 40px;
          height: 40px;
        }
      }
    }

  }
  .box-list{
    margin-top: 24px;
    background-color: white;
    .title{
      //height: 96px;
      display: flex;
      justify-content: space-between;
      padding: 24px 12px  0 30px;
      &:first-child{
        font-family: 'PingFangSC-Regular', serif;
        font-weight: 600;
        font-size: 34px;
        color: #333333;
        line-height: 48px;
        font-style: normal;
      }
      .title-end{
        font-family: 'PingFangSC-Regular', serif;
        font-weight: 400;
        font-size: 28px;
        color: #666666;
        line-height: 40px;
        font-style: normal;
        .end-num{
          font-family: 'PingFangSC-Regular', serif;
          color: #333333;
        }
        .end-DW{
          font-size: 24px;
          color: #666666;
        }
      }
    }
    .numList{
      padding:20px 30px 20px 30px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap:40px 36px;
      .ega {
        display: flex;
        flex-direction: column;
        //gap:4px;
        width: 140px;
        overflow: hidden;
        text-align: center;
        //white-space: nowrap;
        //text-overflow: ellipsis;
        //-o-text-overflow:ellipsis;
        .number {
          //white-space: nowrap;
          //overflow: hidden;
          //text-overflow:ellipsis;
          font-weight: bold;
          font-size: 44px;
          color: #333333;
          line-height: 56px;
          text-align: center;
          font-style: normal;
        }
        .type {
          //white-space: nowrap;
          //overflow: hidden;
          //text-overflow:ellipsis;
          font-weight: 400;
          font-size: 28px;
          color: #666666;
          line-height: 40px;
          text-align: center;
          font-style: normal;
        }
      }
    }
  }
}
</style>
